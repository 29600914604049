import { useNavigate } from 'react-router-dom'

import googleSheetsLogo from '../../assets/google-sheets-logo.png'
import LandingPage from '../../components/landing-page/landing-page'
import { appIconStyle } from '../../style/app-icon-style'

const GoogleSheets = () => {
  const navigate = useNavigate()
  const handleClick = e => {
    e.preventDefault()
    navigate('/google-sheets-settings')
  }
  const googleSheetsSections = [
    {
      header: 'What will this application need access to?',
      statements: [
        'Your User details',
        'Client details',
        'Product details',
        'Booking details'
      ]
    },

    {
      header: 'What will this application do?',
      statements: [
        'Ability to add new Clients in Thryv.',
        'Ability to update existing Clients in Thryv.',
        'Ability to add a new Product in Thryv.',
        'Ability to add Booking in Thryv.',
        'Export Clients, Products and Bookings.'
      ]
    }
  ]

  return (
    <LandingPage
      sections={googleSheetsSections}
      onSubmit={handleClick}
      appName="Google Sheets"
      appIconSource={googleSheetsLogo}
      appIconStyle={appIconStyle}
    />
  )
}

export default GoogleSheets
