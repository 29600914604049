import { Header, Icon, ParagraphText } from '@thryvlabs/maverick'

import {
  CardContainer,
  StatementsContainer
} from '../../style/landing-page-style'

const Sections = ({ sections }) => {
  return sections.map((section, index) => {
    return (
      <CardContainer key={index}>
        <Header fontWeight="medium" variant="h3">
          {section.header}
        </Header>
        {section.statements.map(statement => {
          return (
            <StatementsContainer key={statement}>
              <Icon
                type="solid"
                variant="circleCheck"
                color="green"
                className="icon"
              />
              <ParagraphText variant="lg" color="black">
                {statement}
              </ParagraphText>
            </StatementsContainer>
          )
        })}
      </CardContainer>
    )
  })
}

export default Sections
